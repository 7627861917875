import React from "react";
import {Helmet} from "react-helmet";

import DefaultLayout from "../components/layouts/default";
import Logo from "../components/logo/Logo";
import KyleVideo from "../assets/video/kyle-magnifying-glass-video.mp4";

import "../styles/404.scss";

export default class FourOhFour extends React.Component {
    render() {
        return (<DefaultLayout>
            <Helmet>
                <title>{ 'Page not found!' }</title>
            </Helmet>
            <Logo />
            <div className="four-oh-four-content">
                <video playsinline="true" muted="true" autoplay="true" loop="true">
                    <source src={KyleVideo} type="video/mp4" />
                </video>
                <h2 className="four-oh-four-header">404 not found!</h2>
            </div>
        </DefaultLayout>);
    }
}
